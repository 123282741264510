import styled from "styled-components";

export const Primarybutton = styled.button`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 10px 25px;
  background: #336cfb;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "auto"};

  a {
    color: #fff;
  }

  &:hover {
    opacity: 85%;
  }

  &:disabled {
    opacity: 65%;
    cursor: not-allowed;
  }

  &.lined {
    color: #001a78;
    border: 2px solid #001a78;
    background: none;
    &:hover {
      background: #001a78;
      color: #fff;
    }
  }

  &.lined-white {
    color: #fff;
    border: 2px solid #fff;
    background: none;

    a {
      color: #fff;
    }

    &:hover {
      background: #fff;
      color: #001a78;

      a {
        color: #001a78;
      }
    }
  }

  &.lined-lightBlue {
    color: #336cfb;
    border: 1px solid #336cfb;
    background: none;

    a {
      color: #336cfb;
    }

    &:hover {
      background: #336cfb;
      color: #ffffff;

      a {
        color: #ffffff;
      }
    }
  }

  &.orange {
    color: #fff;
    background: #ea5114;
    a {
      color: #fff;
    }
  }

  &.selected {
    color: #fff;
    background: #336cfb;
    a {
      color: #fff;
    }
  }

  &.thin {
    padding: 7px 15px;
  }

  &.teal {
    color: #fff;
    background: #10a6bd;
    a {
      color: #fff;
    }
  }

  &.teal-lined {
    color: #fff;
    border: 1px solid #10a6bd;
    background: none;

    &:hover {
      background: #10a6bd;
      color: #fff;
    }
  }

  &.lightYellow {
    color: #fff;
    background: #fbf4ec;
    a {
      color: #fff;
    }
  }
  &.lightGreen {
    color: #3ba12f;
    background: #cbecc8;
    a {
      color: #3ba12f;
    }
  }

  &.lightBlue {
    color: #10a6bd;
    background: rgba(16, 166, 189, 0.15);
    a {
      color: #10a6bd;
    }
  }

  &.black {
    color: #fff;
    background: #030303;

    a {
      color: #030303;
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.makeLink {
    background: transparent;
    color: #000;
    text-decoration: underline;
    padding: 0 !important;
    height: auto !important;
    font-size: 14px !important;
  }
`;

export const InlineFormHolder = styled.div`
  margin: auto;
  margin-top: ${(props) => (props.mt ? props.mt : "100px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "100px")};
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: black;

  h4 {
    color: #001a78;
    font-size: 30px;
    margin-bottom: 40px;
  }

  .form-section-title {
    color: #001a78;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  ${Primarybutton} {
    padding: 10px 20px;
    &.makeLink {
      margin-top: 15px;
    }
  }
  .assignAppointment {
    label {
      text-align: left;
      margin: 5px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;
export const ButtonWrapperRow = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
`;
